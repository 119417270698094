import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { User } from 'oidc-client-ts';
import userManager from '@/utilities/auth';

export const useUserStore = defineStore('user', () => {
    const user = ref<User | null>(null);

    const trySilentSignIn = async () => {
        try {
            await userManager.signinSilent();
        } catch (e) {
            console.error(e);
            await userManager.signinRedirect();
        }
    };

    const initUser = async () => {
        user.value = await userManager.getUser();
        if (!user.value) {
            await userManager.signinRedirect();
        } else if (user.value.expired) {
            await trySilentSignIn();
        }
    };

    userManager.events.addUserLoaded((_user: User) => {
        user.value = _user;
    });

    userManager.events.addSilentRenewError(async error => {
        console.error('Silent renew error:', error);
        await userManager.signinRedirect();
    });

    return { user, initUser };
});
