import { UserManager, type UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

const baseUrl = import.meta.env.VITE_BASE_URL;
const clientId = import.meta.env.VITE_CLIENT_ID;
const issueUrl = import.meta.env.VITE_ISSUER_URL;
const scope = import.meta.env.VITE_SCOPE;

const settings: UserManagerSettings = {
    authority: issueUrl,
    client_id: clientId,
    redirect_uri: `${baseUrl}/callback`,
    response_type: 'code',
    scope: scope,
    post_logout_redirect_uri: baseUrl,
    userStore: new WebStorageStateStore({ store: window.localStorage })
};

const userManager = new UserManager(settings);

export default userManager;
