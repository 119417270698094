<template>
    <div class="sm-btn-container-foreground" id="tab-navigation-bar" :style="cssVariables" ref="btnContainerRef">
        <div
            v-for="(button, index) in options"
            :key="`simple-btn-${index}`"
            class="sm-btn-container"
            @click="handleButtonClick(button, index)"
        >
            <div class="sm-btn-item">
                <div :class="['sm-btn-icon', { 'sm-btn-icon-active': currSelected === index }]">
                    <slot name="icon" :props="button">
                        <i class="text-2xl" :class="`${button.icon}`" />
                    </slot>
                </div>

                <div :class="['sm-btn-title', { 'sm-btn-title-active': currSelected === index }]">
                    <slot name="title" :props="button">
                        {{ button.title }}
                    </slot>
                </div>
            </div>
        </div>
        <div ref="borderSwiperRef" class="border-swiper" />
    </div>
</template>

<script setup lang="ts">
import type { SwipeOption } from '@/interfaces/SwipeOption';
import { useRoute, useRouter } from 'vue-router';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

type SwipeProps = {
    modelValue: number | string | null;
    options: SwipeOption[];
    backgroundColor?: string;
};

const props = withDefaults(defineProps<SwipeProps>(), {
    modelValue: null,
    options: () => [],
    backgroundColor: '#FFFFFF'
});
const emit = defineEmits(['update:modelValue']);

const router = useRouter();
const route = useRoute();

const prevSelected = ref<number>(0);
const currSelected = ref<number>(0);
const enableWatch = ref<boolean>(true);
const btnItemWidth = ref<number>(0);
const borderSwiperRef = ref<InstanceType<typeof HTMLElement>>();
const btnContainerRef = ref<InstanceType<typeof HTMLElement>>();

const cssVariables = computed(() => ({
    '--background-color': props.backgroundColor
}));

watch(
    () => currSelected.value,
    newVal => {
        if (borderSwiperRef.value) {
            borderSwiperRef.value.style.transform = `translateX(${btnItemWidth.value * newVal}px)`;
        }
    }
);

watch(
    () => props.modelValue,
    async (newVal, oldVal) => {
        if (newVal != oldVal && enableWatch.value) {
            const target = props.options.findIndex(option => option.id == newVal);

            if (target > -1) {
                await handleButtonClick(props.options[target], target);
            }
        }
    }
);

watch(
    route,
    newRoute => {
        if (newRoute) {
            nextTick(async () => {
                const target = props.options.findIndex((option: SwipeOption) => {
                    if (typeof option.path === 'string') {
                        return option.path === newRoute.path;
                    } else {
                        return (option.path || {}).name === newRoute.name;
                    }
                });
                if (target > -1) {
                    await handleButtonClick(props.options[target], target);
                }
            });
        }
    },
    { immediate: true }
);

function cssLoader() {
    if (btnContainerRef.value && Array.isArray(btnContainerRef.value)) {
        btnItemWidth.value = btnContainerRef.value[0].offsetWidth;
    }

    if (btnContainerRef.value && btnContainerRef.value.firstChild) {
        const firstChild = btnContainerRef.value?.children[0] as HTMLElement;
        btnItemWidth.value = firstChild.offsetWidth;
    }

    if (borderSwiperRef.value) {
        borderSwiperRef.value.style.width = btnItemWidth.value + 'px';
        borderSwiperRef.value.style.transform = `translateX(${btnItemWidth.value * currSelected.value}px)`;
    }
}

async function handleButtonClick(button: SwipeOption, index: number) {
    if (index === currSelected.value) {
        return;
    }

    currSelected.value = index;

    prevSelected.value = currSelected.value;
    await updateValue(button);
}

async function updateValue(button: SwipeOption) {
    emit('update:modelValue', button.id);

    enableWatch.value = false;
    setTimeout(() => {
        enableWatch.value = true;
    }, 0);

    if (button.path && Object.keys(button.path).length) {
        await router.push(button.path);
    }
}

onMounted(() => {
    cssLoader();
    window.addEventListener('resize', cssLoader);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', cssLoader);
});

const index = props.options.findIndex(item => {
    if (item.id == props.modelValue) {
        return true;
    }

    if (typeof item.path === 'object') {
        return (item.path || {}).name == (route || {}).name;
    }

    return false;
});

if (index > -1) {
    currSelected.value = index;
    prevSelected.value = index;
}
</script>

<style scoped>
.sm-btn-container-foreground {
    position: fixed;
    display: flex;
    align-items: flex-end;
    direction: ltr;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2147483647;
    height: var(--tab-navigation-height);
    background: v-bind(backgroundColor);
    box-shadow: 0 0 5px 0 #eee;
}

.sm-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-basis: 100%;
    transition: all 0.3s;
}

@media (min-width: 576px) {
    .sm-btn-container {
        cursor: pointer;
    }
}

.sm-btn-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #0000008a;
}

.sm-btn-icon {
    font-size: 18px;
    transition: all 0.17s ease-in-out;
}

.sm-btn-icon-active {
    color: theme('colors.primary.500');
    margin-bottom: 20px;
}

.sm-btn-title {
    position: absolute;
    color: theme('colors.primary.500');
    transition: all 0.15s ease-in-out;
    top: 70px;
    font-size: 14px;
}

.sm-btn-title-active {
    color: theme('colors.primary.500');
    top: 35px;
}

.border-swiper {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background: theme('colors.primary.500');
    transition: all 0.3s;
}
</style>
