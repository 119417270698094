import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/user';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/pages/Home.vue')
        },
        {
            path: '/search',
            name: 'search',
            component: () => import('@/pages/Search.vue')
        },
        {
            path: '/anime-loads/:name',
            name: 'anime-loads-detail',
            component: () => import('@/pages/Detail/AnimeLoadsDetail.vue')
        },
        {
            path: '/downloads',
            name: 'downloads',
            component: () => import('@/pages/Downloads.vue')
        },
        {
            path: '/subs',
            name: 'subs',
            component: () => import('@/pages/Subs.vue')
        },
        {
            path: '/callback',
            name: 'callback',
            component: () => import('@/pages/Callback.vue'),
            meta: { allowAnonymous: true }
        },
        {
            path: '/:pathMatch(.*)*',
            component: () => import('@/pages/404.vue')
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.allowAnonymous) {
        return next();
    }

    const userStore = useUserStore();
    if (!userStore.user) {
        await userStore.initUser();
    }

    if (userStore.user) {
        next();
    }
});

router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        if (!to?.fullPath) {
            window.location.reload();
        } else {
            window.location.href = to.fullPath;
        }
    }
});

export default router;
