import './assets/style.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'flag-icons/css/flag-icons.min.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';

import App from './App.vue';
import router from './router';
import Ripple from 'primevue/ripple';
import Tooltip from 'primevue/tooltip';

import '@/utilities/init-zod';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Lara from '@primevue/themes/lara';
import { definePreset } from '@primevue/themes';

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);

const LaraPurple = definePreset(Lara, {
    semantic: {
        primary: {
            50: '{indigo.50}',
            100: '{indigo.100}',
            200: '{indigo.200}',
            300: '{indigo.300}',
            400: '{indigo.400}',
            500: '{indigo.500}',
            600: '{indigo.600}',
            700: '{indigo.700}',
            800: '{indigo.800}',
            900: '{indigo.900}',
            950: '{indigo.950}'
        }
    }
});

app.use(PrimeVue, {
    theme: {
        preset: LaraPurple,
        options: {
            cssLayer: {
                name: 'primevue',
                order: 'tailwind-base, primevue, tailwind-utilities'
            },
            darkModeSelector: '.dark'
        }
    },
    ripple: true
});

app.use(VueQueryPlugin, {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                throwOnError: error => {
                    console.error(error);
                    return false;
                }
            }
        }
    }
});

app.mount('#app');
