<template>
    <div class="flex" :class="isMobile ? ['flex-col h-full w-full'] : ''">
        <SidebarNavigation v-if="!isMobile" v-model:selected="selected" :options="options" />
        <div class="overflow-auto p-8" :class="isMobile ? ['view-height'] : ['h-screen w-full']">
            <RouterView v-slot="{ Component, route }">
                <keep-alive>
                    <component :is="Component" :key="route.fullPath" />
                </keep-alive>
            </RouterView>
        </div>
        <TabNavigationBar
            v-if="isMobile"
            v-model="selected"
            background-color="#FFFFFF"
            :options="options"
            class="flex-none"
        />
    </div>
</template>

<script setup lang="ts">
import type { SwipeOption } from '@/interfaces/SwipeOption';
import { isMobile } from '@/composables/mobile';
import { ref } from 'vue';

const options = ref<SwipeOption[]>([
    { title: 'Home', icon: 'mdi mdi-home-outline', path: { name: 'home' }, id: 1 },
    { title: 'Suche', icon: 'mdi mdi-magnify', path: { name: 'search' }, id: 2 },
    { title: 'Downloads', icon: 'mdi mdi-cloud-download-outline', path: { name: 'downloads' }, id: 3 },
    { title: 'Subs', icon: 'mdi mdi-bell-ring-outline', path: { name: 'subs' }, id: 4 }
]);
const selected = ref<string | number>(1);
</script>

<style scoped>
.view-height {
    height: calc(100% - var(--tab-navigation-height));
}
</style>
