<template>
    <div class="h-screen bg-white flex-shrink-0 block w-[7rem] select-none border-right">
        <div class="flex flex-col h-full">
            <div class="flex justify-center items-center flex-shrink-0 h-[60px]">
                <img
                    src="https://blocks.primevue.org/images/blocks/logos/hyper-cyan.svg"
                    alt="Image"
                    class="h-[30px]"
                />
            </div>
            <div class="mt-3">
                <ul class="list-none p-0 m-0">
                    <li v-for="option in options" :key="option.id" class="p-ripple" v-ripple>
                        <a
                            class="flex flex-col items-center cursor-pointer p-3 justify-center border-left hover:border-surface-300 transition-duration-150 transition-colors"
                            :class="option.id === selected ? optionSelectedClasses : optionClasses"
                            @click="openRoute(option.id, option.path)"
                        >
                            <i class="mr-0 mb-2 text-2xl" :class="[option.icon]"></i>
                            <span class="font-medium text-xs block">{{ option.title }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="mt-auto">
                <Divider />
                <ul class="list-none p-0 m-0">
                    <li class="p-ripple" v-ripple @click="switchThemeMode">
                        <a
                            class="flex flex-col items-center cursor-pointer p-3 justify-center border-left hover:border-surface-300 transition-duration-150 transition-colors"
                        >
                            <i class="mdi mdi-brightness-6 mr-0 mb-2 text-2xl"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Path, SwipeOption } from '@/interfaces/SwipeOption';
import { useRoute, useRouter } from 'vue-router';
import { nextTick, watch } from 'vue';

const selected = defineModel<string | number>('selected');
const props = defineProps<{ options: SwipeOption[] }>();
const router = useRouter();
const route = useRoute();

const optionSelectedClasses = ['text-cyan-600', 'border-cyan-600'];
const optionClasses = ['text-surface-600', 'border-transparent'];

async function openRoute(id: string | number, path: string | Path | undefined) {
    if (!path) return;
    selected.value = id;
    await router.push(path);
}

watch(
    route,
    newRoute => {
        if (newRoute) {
            nextTick(async () => {
                const option = props.options.find((option: SwipeOption) => {
                    if (typeof option.path === 'string') {
                        return option.path === newRoute.path;
                    } else {
                        return (option.path || {}).name === newRoute.name;
                    }
                });
                if (option) {
                    selected.value = option.id;
                }
            });
        }
    },
    { immediate: true }
);

function switchThemeMode() {
    if (!document.documentElement.classList.contains('dark')) document.documentElement.classList.add('dark');
    else document.documentElement.classList.remove('dark');
}
</script>

<style scoped>
.border-right {
    border-right-width: 1px !important;
    border-right-style: solid;
}

.border-left {
    border-left-width: 2px !important;
    border-left-style: solid;
}

.transition-duration-150 {
    transition-duration: 0.15s !important;
}
</style>
