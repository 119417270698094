import { z } from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.too_small && issue.type === 'number') {
        return { message: `Zahl muss größer oder gleich ${issue.minimum} sein.` };
    } else if (issue.code === z.ZodIssueCode.too_small || issue.code === z.ZodIssueCode.invalid_type) {
        return { message: 'Das Feld darf nicht leer sein.' };
    }

    return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
